var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.orgStructure)?_c('div',{staticClass:"company_structure"},[(!_vm.selectedDepartment )?_c('div',[_c('h4',[_vm._v("Организационная структура ООО \"Транспортные Технологии\"")]),_c('p',{staticClass:"manager"},[_vm._v("Генеральный директор: "+_vm._s(_vm.findCEO().last_name)+" "+_vm._s(_vm.findCEO().first_name))]),_c('div',{staticClass:"company_structure_content"},[_vm._l((_vm.departments),function(department){return [(department.subordinates.length > 0)?_c('div',{key:department.id,staticClass:"list_item",on:{"click":function($event){return _vm.selectDepartment(department)}}},[_c('p',{staticClass:"header"},[_vm._v(_vm._s(department.department))]),_c('p',{staticClass:"description"},[_vm._v(" Начальник: "+_vm._s(department.first_name)+" "+_vm._s(department.last_name)+" ")])]):_vm._e()]})],2),_c('hr'),_c('p',{staticClass:"text"},[_vm._v("Сотрудники на прямую подчиняющиеся Ген.директору")]),_c('table',{staticClass:"custom-table"},[_vm._m(0),_c('tbody',[_vm._l((_vm.departments),function(emp){return [(emp.subordinates.length === 0)?_c('tr',{key:emp.id},[_c('td',[_vm._v(_vm._s(emp.last_name)+" ")]),_c('td',[_vm._v(_vm._s(emp.first_name))]),_c('td',[_vm._v(_vm._s(emp.middle_name))]),_c('td',[_vm._v(_vm._s(emp.post))])]):_vm._e()]})],2)])]):_c('div',[_c('h4',[_vm._v(_vm._s(_vm.selectedDepartment.department))]),_c('p',{staticClass:"description"},[_vm._v(" Начальник: "+_vm._s(_vm.selectedDepartment.first_name)+" "+_vm._s(_vm.selectedDepartment.last_name)+" ")]),(
          _vm.selectedDepartment.subordinates &&
          _vm.selectedDepartment.subordinates.length &&
          _vm.selectedDepartment.subordinates.some(
            (sub) => sub.subordinates.length > 0
          )
        )?_c('div',[_c('div',{staticClass:"company_structure_content"},_vm._l((_vm.selectedDepartment.subordinates),function(sub){return _c('div',{key:sub.id,staticClass:"list_item",on:{"click":function($event){return _vm.selectDepartment(sub)}}},[_c('p',{staticClass:"header"},[_vm._v(_vm._s(sub.department))]),_c('p',{staticClass:"description"},[_vm._v(" Начальник:"+_vm._s(sub.first_name)+" "+_vm._s(sub.last_name)+" ")])])}),0)]):_c('div',[_c('p',{staticClass:"text"},[_vm._v("Сотрудники отдела")]),_c('div',{staticClass:"employee_block"},[_c('table',{staticClass:"custom-table"},[_vm._m(1),_c('tbody',_vm._l((_vm.selectedDepartment.subordinates),function(sub){return _c('tr',{key:sub.id},[_c('td',[_vm._v(_vm._s(sub.last_name)+" ")]),_c('td',[_vm._v(_vm._s(sub.first_name))]),_c('td',[_vm._v(_vm._s(sub?.middle_name))]),_c('td',[_vm._v(_vm._s(sub?.post))])])}),0)])])]),_c('button',{staticClass:"backList",on:{"click":_vm.goBack}},[_c('b-icon',{attrs:{"icon":"chevron-left","scale":"1"}}),_vm._v(" Вернуться назад ")],1)])]):_c('div',{staticClass:"loading"},[_c('p',[_vm._v("Загрузка организационной структуры...")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Фамилия")]),_c('th',[_vm._v("Имя")]),_c('th',[_vm._v("Отчество")]),_c('th',[_vm._v("Должность")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Фамилия")]),_c('th',[_vm._v("Имя")]),_c('th',[_vm._v("Отчество")]),_c('th',[_vm._v("Должность")])])])
}]

export { render, staticRenderFns }